<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
        <v-col cols="10"></v-col>
        <v-col cols="2">
            <v-btn cols="2" class="success" @click.stop="onDownload">
                <v-icon>get_app</v-icon>
                Export
            </v-btn>
        </v-col>
    </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        close-on-content-click
                                                        chips
                                                        clearable
                                                        color="blue-grey lighten-2"
                                                        :label="$t('report_type')"
                                                        item-text="value"
                                                        item-value="id"
                                                        class="pa-0">
                                            <!-- Template for render selected data -->
                                            <template slot="selection"
                                                      slot-scope="data">
                                                <v-chip :input-value="data.selected"
                                                        class="chip--select ma-0 ">
                                                    {{ data.item.value }}
                                                </v-chip>
                                            </template>
                                            <!-- Template for render data when the select is expanded -->
                                            <template slot="item"
                                                      slot-scope="data">
                                                <!-- Divider and Header-->
                                                <template v-if="typeof data.item !== 'object'">
                                                    <div v-text="data.item" />
                                                </template>
                                                <!-- Normal item -->
                                                <template v-else>
                                                    <div>
                                                        <div v-html="data.item.value"></div>
                                                    </div>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <!-- <v-col class="d-flex" cols="12" sm="3">
                                <v-autocomplete v-model="serverParams.ReportCat"
                                                :items="ReportCats"
                                                label="Pilgrimage Name"
                                                clearable
                                                single-line
                                                item-text="value"
                                                item-value="value"
                                ></v-autocomplete>
                            </v-col>-->
                                    <!-- <v-col class="d-flex" cols="12" sm="2">
                                <v-autocomplete v-model="serverParams.isIndian"
                                                :items="ReportInclusionCats"
                                                label="Inclusion"
                                                clearable
                                                single-line
                                                item-text="value"
                                                item-value="id"
                                ></v-autocomplete>
                            </v-col>-->

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-menu v-model="snackbar.menu1"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              :label="$t('from_date')"
                                                              clearable
                                                              prepend-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="4" v-if="!reportIsPOECountryWise && !reportIsPOEMediumWise">
                                        <v-menu v-model="snackbar.menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('to_date')"
                                                              clearable
                                                              prepend-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex text-center" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="table-container">
                <table v-if="this.table1" class="myCustomTable">
                    <thead>
                        <tr>
                            <th rowspan="2">{{$t('year')}}</th>
                            <th colspan="2">{{$t('total')}}</th>
                            <th colspan="2">{{$t('by_air')}}</th>
                            <th colspan="2">{{$t('by_land')}}</th>
                            <th rowspan="2">{{$t('average_length_of_stay')}}</th>
                        </tr>
                        <tr>
                            <th>{{$t('number')}}</th>
                            <th>{{$t('anuual_growth_rate')}}</th>
                            <th>{{$t('number')}}</th>
                            <th>{{$t('percent')}}</th>
                            <th>{{$t('number')}}</th>
                            <th>{{$t('percent')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in AverageLengthOfStayAndByPortOfEntry" :key="row">
                            <td>{{row.year}}</td>
                            <td>{{row.number}}</td>
                            <td>{{row.annualGrowthRate}}</td>
                            <td>{{row.numberByAir}}</td>
                            <td>{{row.percentByAir}}</td>
                            <td>{{row.numberByLand}}</td>
                            <td>{{row.percentByland}}</td>
                            <td>{{row.averageLengthOfStay}}</td>
                        </tr>
                    </tbody>
                </table>
                <table v-if="this.table2" class="myCustomTable">
                    <thead>
                        <tr>
                            <th>{{$t('year')}}</th>
                            <th>{{$t('number')}}</th>
                            <th>{{$t('average_length_of_stay')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="stay in AverageLengthOfStay" :key="stay">
                            <td>{{stay.year}}</td>
                            <td>{{stay.number}}</td>
                            <td>{{stay.averageLengthOfStay}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-center" v-if="isLoading">
                <v-progress-circular :size="50"
                                     color="primary"
                                     indeterminate></v-progress-circular>

            </div>
            <!--            <div class="table-container">-->
            <!--                <table class="myCustomTable">-->
            <!--                    <thead>-->
            <!--                    <tr>-->
            <!--                       <td v-for="(column,index) in columns" :key="'theColumn'+index">-->
            <!--                           {{column.label}}-->
            <!--                       </td>-->
            <!--                    </tr>-->
            <!--                    </thead>-->
            <!--                    <tbody>-->
            <!--                    <template v-for="(row,index) in rows">-->
            <!--                        <tr :key="index">-->
            <!--                            <td :rowspan="(row.data?row.data.length:0)+1">{{row.year | moment("YYYY")}}</td>-->
            <!--                        </tr>-->
            <!--                        <template v-for="(secondRow,counter) in row.data">-->
            <!--                            <tr :key="'unique_column' + counter">-->
            <!--                                <template v-for="(rowItem,index) in secondRow">-->
            <!--                                    <td  :key="'rowItem-'+index" v-if="index!=='Year'">-->
            <!--                                        {{rowItem}}-->
            <!--                                    </td>-->
            <!--                                </template>-->
            <!--                            </tr>-->
            <!--                        </template>-->
            <!--                    </template>-->
            <!--                    </tbody>-->
            <!--                </table>-->
            <!--            </div>-->
        </v-container>
        <v-row class="mt-7">
            <div class="col-12" style="color: gray; text-align: right">
                Source : Department of Immigration
            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ArrivalTourist-ArrivalReport",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            async getFilterData() {
                try {
                    const {data} = await axios.get('Pilgrimage/DDLPilGrimageListAsync')
                    this.ReportCats = data
                } catch (e) {
                    console.log(e)
                }
            },
            formResponse(data) {
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async onDownload() {

                let param = {
                    ToDate: this.serverParams.ToDate,
                    FromDate: this.serverParams.FromDate,
                };

                if (this.serverParams.ReportType == "AverageLengthOfStay") {
                    this.isLoading = true;
                    await axios({
                        url: 'Immigration/DownloadAverageLengthOfStay',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'AverageLengthOfStay.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType == "AverageLengthOfStayAndByPortOfEntry") {
                    this.isLoading = true;
                    await axios({
                        url: 'Immigration/DownloadAverageLengthOfStayByPortOfEntry',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'AverageLengthOfStayByPortOfEntry.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;

                    });
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            async loadItems() {
                this.isLoading = true;
                let param = {
                    ToDate: this.serverParams.ToDate,
                    FromDate: this.serverParams.FromDate,
                };
                if (this.serverParams.ReportType == "AverageLengthOfStay") {
                    this.AverageLengthOfStayAndByPortOfEntry = []
                        this.table1=false
                        this.table2=true
                    axios.post('Immigration/GetArrivalAndAverageLengthOfStayReportListAsyc', param)
                        .then(response => {
                            this.AverageLengthOfStay = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }
                if (this.serverParams.ReportType == "AverageLengthOfStayAndByPortOfEntry") {
                    this.isLoading = true;
                    this.AverageLengthOfStay = []
                    this.table1=true
                    this.table2=false
                    axios.post('Immigration/GetArrivalAndAverageLengthOfStayAndByPortOfEntryReportListAsyc', param)
                        .then(response => {
                            this.AverageLengthOfStayAndByPortOfEntry = response.data;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }


            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.getFilterData()
            /* this.loadItems()*/
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                items: [
                    {
                        text: "Reports",
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: "Tourist Arrival Report Menu",
                        exact: true,
                        to: '/reportModules/reportTourist-Arrival'
                    },
                    {
                        text: "Length Of Stay",
                        disabled: true,
                        exact: true
                    }],
                columns: [],
                table1:false,
                table2:false,
                isLoading:false,
                AverageLengthOfStay: [],
                AverageLengthOfStayAndByPortOfEntry: [],
                ReportTypes: [
                    {id: 'AverageLengthOfStay', value: 'Average Length Of Stay'},
                    {id: 'AverageLengthOfStayAndByPortOfEntry', value: 'Average Length Of Stay And By PortOfEntry'},
                ],
                ReportCats: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    ReportType: "",
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    isIndian: "",
                    ReportCat: "",
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;
    .myCustomTable {
        text-align: center;
        width: fit-content;
        min-width: 100%;

        thead,tbody {
            width: 100%;
        }
        thead {
            width: 100%;
            background-color: $blue;
            color: white;
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }
    .headerBackground{
        background-color: $blue;
        color: white;
    }
</style>
